<template>
  <div ref="mychart" />
</template>

<script>
const echarts = window.echarts;

export default {
  props: {
    options: {
      type: Object,
    },
  },
  data() {
    return {
      mychart: null,
      canResize: true,

      //
    };
  },
  watch: {
    options() {
      this.createChart();
    },
  },
  mounted() {
    this.createChart();

    window.addEventListener('resize', this.resize);

    //
  },
  destroyed() {
    this.mychart.dispose();
    window.removeEventListener('resize', this.resize);

    //
  },
  methods: {
    // 窗口尺寸变化
    resize() {
      if (!this.canResize) return;

      this.canResize = false;

      this.mychart.resize();

      setTimeout(() => {
        this.canResize = true;
      }, 1000);

      //
    },

    // 建造视图
    createChart() {
      if (!this.$props.options) return;

      this.mychart = echarts.init(this.$refs['mychart']);

      // 绘制图表
      this.mychart.setOption(this.$props.options);

      //
    },

    //
  },
};
</script>

<style lang="scss" scoped>
</style>
